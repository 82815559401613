import { Program } from '../../model/program';
import { factory } from '../../shared/app-logging';
import { Product } from '../../model/product';
import { Operator } from '../../model/operator';
import * as i0 from "@angular/core";
import * as i1 from "../../services/operators.service";
import * as i2 from "../../services/operator-programs.service";
import * as i3 from "../../services/locales.service";
import * as i4 from "../../services/program-products.service";
import * as i5 from "./template-bulk-edit-export/template-bulk-edit-export.component";
import * as i6 from "./template-bulk-edit-import/template-bulk-edit-import.component";
const logger = factory.getLogger('TemplateBulkEditComponent');
export class TemplateBulkEditComponent {
    constructor(operatorsService, operatorProgramsService, localesService, programProductsService) {
        this.operatorsService = operatorsService;
        this.operatorProgramsService = operatorProgramsService;
        this.localesService = localesService;
        this.programProductsService = programProductsService;
    }
    ngOnInit() {
        // Load Locales
        this.localesService.findLocales().subscribe(locales => {
            this.locales = locales;
            // Select default Locale
            if (this.locales) {
                this.localeSel = this.locales.find(l => l.localeName === 'en_US');
            }
        });
        // Load Operators
        this.operatorsService.findOperators().subscribe(operators => {
            logger.debug(`${operators.length} operators loaded`);
            if (operators) {
                // Load Programs
                this.operatorProgramsService.findProgramsWithOperator(operators).subscribe(programs => {
                    logger.debug(`${programs.length} programs loaded`);
                    // Select default Program
                    if (programs) {
                        // Load Products
                        this.programProductsService.findProductsWithProgram(programs).subscribe(products => {
                            logger.debug(`${products.length} products loaded`);
                            const defaultOperator = new Operator(null, 'DEFAULT');
                            const defaultProgram = new Program(null, defaultOperator, 'DEFAULT', 'DEFAULT', 'DEFAULT');
                            const defaultProduct = new Product(null, defaultProgram, 'DEFAULT');
                            // Insert default Operator-Program-Product at the beginning
                            this.products = [defaultProduct].concat(this.programProductsService.sortProducts(products));
                            // Select default Product
                            if (this.products) {
                                this.productSel = this.products[0];
                            }
                        });
                    }
                });
            }
        });
    }
    static { this.ɵfac = function TemplateBulkEditComponent_Factory(t) { return new (t || TemplateBulkEditComponent)(i0.ɵɵdirectiveInject(i1.OperatorsService), i0.ɵɵdirectiveInject(i2.OperatorProgramsService), i0.ɵɵdirectiveInject(i3.LocalesService), i0.ɵɵdirectiveInject(i4.ProgramProductsService)); }; }
    static { this.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: TemplateBulkEditComponent, selectors: [["app-template-bulk-edit"]], decls: 10, vars: 8, consts: [[1, "page-section"], [1, "margin-lg"], [3, "localeSel", "productSel", "locales", "products", "localeSelChange", "productSelChange"], [1, "page-section", "padding-top-lg"], [3, "localeSel", "productSel", "locales", "products"], [1, "margin-md"]], template: function TemplateBulkEditComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "section")(1, "p", 0);
            i0.ɵɵtext(2, "Template Content Data Export");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(3, "div", 1)(4, "app-template-bulk-edit-export", 2);
            i0.ɵɵlistener("localeSelChange", function TemplateBulkEditComponent_Template_app_template_bulk_edit_export_localeSelChange_4_listener($event) { return ctx.localeSel = $event; })("productSelChange", function TemplateBulkEditComponent_Template_app_template_bulk_edit_export_productSelChange_4_listener($event) { return ctx.productSel = $event; });
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(5, "p", 3);
            i0.ɵɵtext(6, "Template Content Data Import");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(7, "div", 1);
            i0.ɵɵelement(8, "app-template-bulk-edit-import", 4);
            i0.ɵɵelementEnd();
            i0.ɵɵelement(9, "p", 5);
            i0.ɵɵelementEnd();
        } if (rf & 2) {
            i0.ɵɵadvance(4);
            i0.ɵɵproperty("localeSel", ctx.localeSel)("productSel", ctx.productSel)("locales", ctx.locales)("products", ctx.products);
            i0.ɵɵadvance(4);
            i0.ɵɵproperty("localeSel", ctx.localeSel)("productSel", ctx.productSel)("locales", ctx.locales)("products", ctx.products);
        } }, dependencies: [i5.TemplateBulkEditExportComponent, i6.TemplateBulkEditImportComponent], styles: [".modal.fade.show[_ngcontent-%COMP%] {\n  opacity: 1;\n}"] }); }
}
