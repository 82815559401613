export const environment = {
    production: false,
    contentLibraryUiRootUrl: 'https://contentlibrary.preprd-apps.enelx.com',
    contentLibraryApiBaseUrl: 'https://contentlibrary-api.preprd-apps.enelx.com',
    ngpServiceCatalogReferenceApiBaseUrl: 'https://reference.ngp.preprd-svcs.enernoc.net',
    ngpServiceCatalogReferenceApiKey: 'GBU1ip0OYX21HkheQVKAL1SavScaPilr61jPxoYU',
    infoPushApiBaseUrl: 'https://info-push-api.preprd-svcs.enelx.net',
    openAmUiRootUrl: 'https://openam.preprd-apps.enelx.com/openam/XUI/',
    openAmUiLoginUrl: 'https://openam.preprd-apps.enelx.com/openam/XUI/?realm=enelx&goto=https://contentlibrary.preprd-apps.enelx.com/&realm=enelx#login/',
    openAmUiLogoutUrl: 'https://openam.preprd-apps.enelx.com/openam/XUI/?realm=enelx#loggedOut/',
    openAmCookieDomain: '.enelx.com',
    userNavApi: 'https://global-nav-v2.preprd-svcs.enernoc.net/api/v2',
    brands: {
        enelx: {
            baseUrl: '/',
        },
    },
    defaults: {
        brand: 'enelx',
        locale: 'en-us',
    }
};
